import { Directive, ElementRef, inject, input } from '@angular/core';
import { User } from 'app/models/api/user';
import { ImgSizeUtil } from 'app/utils/img-size-utils';

@Directive({
    selector: '[avatar]',
})
export class AvatarDirective {
    readonly size = input(60);
    readonly user = input<User>();
    readonly url = input<string>();
    readonly hidePhotoAsAvatar = input(false);

    private readonly element = inject<ElementRef<HTMLImageElement>>(ElementRef);

    ngOnChanges() {
        const user = this.user();
        const urlValue = this.url();
        if (!user && !urlValue) {
            return;
        }

        this.element.nativeElement.parentElement?.classList.add('gradient');

        const url = user?.links?.avatar ?? urlValue;
        if (url && !this.hidePhotoAsAvatar()) {
            const eventListener = (_err: unknown) => {
                this.element.nativeElement.removeEventListener('error', eventListener);
                this.setDefaultAvatar();
            };
            this.element.nativeElement.addEventListener('error', eventListener);
            this.element.nativeElement.src = ImgSizeUtil.transform(url, this.size(), true);
            this.element.nativeElement.srcset = '';
        } else {
            this.setDefaultAvatar();
        }
    }

    private setDefaultAvatar() {
        this.element.nativeElement.parentElement?.classList.remove('gradient');
        const user = this.user();
        if (user) {
            this.element.nativeElement.src = user.defaultAvatar;
        }
    }
}
